<template>
  <v-container>
    <div class="proyectos-all-list mt-1">
      <loading-flux :value="loadingInitial"></loading-flux>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-alert
                :show="segundosMensajeActualizacion"
                dismissible
                :variant="mensaje.variant"
                @dismissed="segundosMensajeActualizacion = 0"
                @dismiss-count-down="actualizarSegundosMensajeActualizacion"
              >
                {{ mensaje.texto }}
              </b-alert>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <section
                    class="d-flex flex-column flex-sm-row justify-content-between"
                  >
                    <h2 class="ml-1 my-3">Proyectos</h2>
                  </section>
                </v-col>
                <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-3">
                  <div v-if="loadingSyncProjects">
                    <span class="mx-2"> Ejecutando sincronización </span>
                    <v-progress-circular
                      indeterminate
                      color="teal accent-4"
                    ></v-progress-circular>
                  </div>
                  <v-btn
                    @click="sendSyncProjects"
                    :disabled="loadingSyncProjects"
                    color="teal accent-4"
                    :dark="false"
                    small
                    class="text-white mt-4"
                  >
                    <i class="mdi mdi-sync me-1"></i>
                    Sincronizar proyectos
                  </v-btn>
                  <!-- VALIDACIÓN COMENTADA POR AHORA PARA CREAR PREVENTAS-->
                  <!-- v-if="
                      verifyUserBelongsToCgestionCostCenter ||
                      currentUserIsSuperAdmin
                    " -->
                  <v-btn
                    @click="emitCreatePresale"
                    :disabled="loadingSyncProjects"
                    color="primary"
                    :dark="false"
                    small
                    class="text-white mt-4 mx-3"
                  >
                    <i class="mdi mdi-plus me-1"></i>
                    Crear Proyecto de Preventa
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  md="3"
                  sm="12"
                  class="mb-2"
                  v-if="filteredProjects && countTotalInitialProjects >= 10"
                >
                  <v-text-field
                    label="Búsqueda"
                    @input="($event) => getFilteredProjectsData()"
                    v-model="filters.filter"
                    clearable
                    class="mt-3"
                    placeholder="Busqueda"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  class="mb-2"
                  v-if="filteredProjects && countTotalInitialProjects >= 10"
                >
                  <v-autocomplete
                    v-model="filters.filter_stage"
                    @change="($event) => getFilteredProjectsData()"
                    :items="stagesProjects"
                    multiple
                    chips
                    class="mt-5 pt-4"
                    deletable-chips
                    item-value="name"
                    item-text="name"
                    clearable
                    label="Filtrar por Etapa"
                    height="20"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="12"
                  class="mb-2"
                  v-if="filteredProjects && countTotalInitialProjects >= 10"
                >
                  <v-autocomplete
                    v-model="filters.items"
                    class="mt-3"
                    @change="($event) => getFilteredProjectsData()"
                    :items="[5, 10, 15, 20, 25]"
                    label="Items por página"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  class="mb-2"
                  v-if="filteredProjects && countTotalInitialProjects >= 10"
                >
                  <v-checkbox
                    v-model="filters.filter_sap"
                    @click="getFilteredProjectsData()"
                    class="mt-4 mx-5"
                  >
                    <template v-slot:label>
                      <h6 class="mt-2 mx-1 mb-0">Proyectos (SAP)</h6>
                    </template>
                  </v-checkbox>
                </v-col>

                <!-- <v-col cols="12" lg="3" md="3" sm="12" class="mt-2">
                  <section class="float-right">
                    <v-btn
                      v-if="rolModuloPermiso.escritura"
                      color="blue darken-3"
                      :dark="false"
                      small
                      class="text-white mt-4"
                      @click="abrirModalNuevo"
                    >
                      <i class="mdi mdi-plus me-1"></i>
                      Nuevo Proyecto
                    </v-btn>
                  </section>
                </v-col> -->
              </v-row>
              <div>
                <!-- Table -->
                <v-data-table
                  id="tabla-proyectos"
                  :page.sync="filters.page"
                  :headers="headers"
                  @page-count="pageCount"
                  :items="
                    filteredProjects && filteredProjects.projectlist
                      ? filteredProjects.projectlist
                      : []
                  "
                  item-key="code"
                  :items-per-page="filters.items"
                  class="elevation-1"
                  mobile-breakpoint="993"
                  hide-default-footer
                  :loading="loadingProjects"
                  @update:sort-by="updateSortBy"
                  @update:sort-desc="updateSortDesc"
                  no-data-text="Sin proyectos asignados"
                  loading-text="Cargando Proyectos... Espere Por favor"
                  @click:row="(item) => irLugarinstalacion(item)"
                >
                  <template v-slot:item.name="{ item }">
                    <div class="link-text text-left">
                      {{ item.name }}
                    </div>
                  </template>

                  <template v-slot:item.stage="{ item }">
                    <v-chip
                      pill
                      class="text-white"
                      :style="{
                        background: obtenerColorEtapa(
                          item.stage ? item.stage.name : null
                        ),
                      }"
                      >{{
                        item.is_sap
                          ? item.sap_stage
                          : item.stage && item.stage.name
                      }}</v-chip
                    >
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    <span v-if="item.created_at != null">{{
                      formatDate(item.created_at)
                    }}</span>
                  </template>
                </v-data-table>
                <div class="text-center pt-2">
                  <v-pagination
                    v-model="filters.page"
                    :length="
                      filteredProjects ? filteredProjects.projectpages : 1
                    "
                    :total-visible="8"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="mostrarModal" title="Proyecto" hide-footer size="lg">
      <loading-flux :value="loading"></loading-flux>
      <form @submit.prevent="submit">
        <div class="card-title mb-3">Información General</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="codigoProyecto">Codigo Proyecto*</label>
              <input
                id="codigoProyecto"
                v-model="$v.form.codigoProyecto.$model"
                type="text"
                class="form-control"
                placeholder="Codigo Proyecto"
                :disabled="esEditar"
                :class="{
                  'is-invalid': submitted && $v.form.codigoProyecto.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.codigoProyecto.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="nombreProyecto">Nombre Proyecto*</label>
              <input
                id="nombreProyecto"
                v-model="$v.form.nombreProyecto.$model"
                type="text"
                class="form-control"
                placeholder="Nombre Proyecto"
                :class="{
                  'is-invalid': submitted && $v.form.nombreProyecto.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.nombreProyecto.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="etapaProyecto">Etapa Proyecto*</label>
              <multiselect
                v-model="$v.form.etapaProyecto.$model"
                :options="etapasProyecto"
                placeholder="Seleccionar Etapa"
                value="etapa_proyecto_id"
                label="nombre_etapa_proyecto"
                :searchable="true"
                :loading="etapasProyectoCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.etapaProyecto.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.etapaProyecto.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="montoProyecto">Monto</label>
              <input
                id="montoProyecto"
                v-model="$v.form.montoProyecto.$model"
                type="number"
                class="form-control"
                placeholder="Monto Proyecto"
                :class="{
                  'is-invalid': $v.form.montoProyecto.$invalid && submitted,
                }"
              />

              <div
                class="invalid-feedback"
                v-if="submitted && $v.form.montoProyecto.$invalid"
              >
                El campo debe ser numérico
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoProyecto">Tipo Proyecto*</label>
              <multiselect
                v-model="$v.form.tipoProyecto.$model"
                :options="tipoProyectos"
                placeholder="Seleccionar Tipo Proyecto"
                value="tipo_proyecto_id"
                label="nombre_tipo_proyecto"
                :searchable="true"
                :loading="tipoProyectosCargando"
                open-direction="bottom"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoProyecto.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.tipoProyecto.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="estado">Estado*</label>
              <multiselect
                v-model="$v.form.estado.$model"
                :options="estados"
                placeholder="Seleccionar Estado"
                value="value"
                label="label"
                :searchable="true"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.estado.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.estado.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoVenta">Tipo Venta*</label>
              <multiselect
                v-model="$v.form.tipoVenta.$model"
                :options="tipoVentas"
                placeholder="Seleccionar Tipo Venta"
                value="tipo_venta_id"
                label="nombre_tipo_venta"
                :searchable="true"
                :loading="tipoVentasCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoVenta.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.tipoVenta.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="direccion">Dirección*</label>
              <input
                id="direccion"
                v-model="$v.form.direccion.$model"
                type="text"
                class="form-control"
                placeholder="Dirección"
                :class="{
                  'is-invalid': submitted && $v.form.direccion.$invalid,
                }"
              />
              <div
                v-if="submitted && $v.form.direccion.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="region">Region*</label>
              <multiselect
                @input="obtenerComunasRegionProyecto()"
                v-model="$v.form.region.$model"
                :options="regiones"
                placeholder="Seleccionar Region"
                value="codigo"
                label="nombre"
                :searchable="true"
                :loading="regionesCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.region.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.region.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="comuna">Comuna*</label>
              <multiselect
                v-model="$v.form.comuna.$model"
                :options="comunasRegion"
                placeholder="Seleccionar Comuna"
                value="codigo"
                label="nombre"
                :searchable="true"
                class="helo comuna"
                :loading="comunasRegionCargando"
                :class="{
                  'is-invalid': submitted && $v.form.comuna.$invalid,
                }"
              ></multiselect>
              <div
                v-if="submitted && $v.form.comuna.$invalid"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="region">Tablero Personalizado</label>
              <multiselect
                v-model="$v.form.panel_especial.$model"
                :options="specialPanels"
                placeholder="Seleccionar Tablero Personalizado"
                :searchable="true"
                class="helo"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="card-title mb-3 mt-3">Información Técnica</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="potenciaPeak">Potencia Peak [W]</label>
              <input
                id="potenciaPeak"
                v-model="$v.form.potenciaPeak.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Potencia Peak [W]"
                :class="{
                  'is-invalid': submitted && $v.form.potenciaPeak.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.potenciaPeak.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="potenciaAC">Potencia AC [W]</label>
              <input
                id="potenciaAC"
                v-model="$v.form.potenciaAC.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Potencia AC [W]"
                :class="{
                  'is-invalid': submitted && $v.form.potenciaAC.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.potenciaAC.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="capacidadBaterias">Capacidad Baterías [KWh]</label>
              <input
                id="capacidadBaterias"
                v-model="$v.form.capacidadBaterias.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Capacidad Bateria [KWh]"
                :class="{
                  'is-invalid': submitted && $v.form.capacidadBaterias.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.capacidadBaterias.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoInstalacion">Tipo Instalación*</label>
              <multiselect
                v-model="$v.form.tipoInstalacion.$model"
                :options="tipoInstalaciones"
                placeholder="Seleccionar Tipo Proyecto"
                value="tipo_instalacion_id"
                label="nombre_tipo_instalacion"
                :searchable="true"
                :loading="tipoInstalacionesCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoInstalacion.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.tipoInstalacion.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="faseInstalacion">Fase Instalacion*</label>
              <multiselect
                v-model="$v.form.faseInstalacion.$model"
                :options="fasesInstalacion"
                placeholder="Seleccionar Fase"
                value="value"
                label="label"
                :searchable="true"
                :loading="fasesInstalacionCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.faseInstalacion.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.faseInstalacion.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoCliente">Tipo Cliente*</label>
              <multiselect
                v-model="$v.form.tipoCliente.$model"
                :options="tipoClientes"
                placeholder="Seleccionar Tipo Cliente"
                value="tipo_cliente_id"
                label="nombre_tipo_cliente"
                :searchable="true"
                :loading="tipoClientesCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoCliente.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.tipoCliente.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="normativa">Normativa*</label>
              <multiselect
                v-model="$v.form.normativa.$model"
                :options="normativas"
                placeholder="Seleccionar Normativa"
                value="normativa_id"
                label="nombre_normativa"
                :searchable="true"
                :loading="normativasCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.normativa.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.normativa.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="clienteElectrico">Cliente Electrico*</label>
              <multiselect
                v-model="$v.form.clienteElectrico.$model"
                :options="clientesElectrico"
                placeholder="Seleccionar Cliente Electrico"
                value="value"
                label="label"
                :searchable="true"
                :loading="clientesElectricoCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.clienteElectrico.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.clienteElectrico.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="capacidadEmpalme">Capacidad Empalme [A]</label>
              <input
                id="capacidadEmpalme"
                v-model="$v.form.capacidadEmpalme.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Capacidad Empalme [A]"
                :class="{
                  'is-invalid': submitted && $v.form.capacidadEmpalme.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.capacidadEmpalme.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 v-application">
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            text
            @click="mostrarModal = false"
            >Cerrar</v-btn
          >
          <v-btn color="blue darken-3" class="text-white mx-2">Guardar</v-btn>
        </div>
      </form>
    </b-modal>
  </v-container>
</template>
<script>
import appConfig from "@/app.config";
import { proyectoMethods } from "@/state/helpers";
import { etapaProyectoMethods } from "@/state/helpers";
import { subEtapaProyectoMethods } from "@/state/helpers";
import { tipoProyectoMethods } from "@/state/helpers";
import { tipoVentaMethods } from "@/state/helpers";
import { localizacionMethods } from "@/state/helpers";
import { tipoClienteMethods } from "@/state/helpers";
import { tipoInstalacionMethods } from "@/state/helpers";
import { normativaMethods } from "@/state/helpers";
import { appFlexEnergeticaMethods } from "@/state/helpers";
import { authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import { COST_CENTER_MANAGMENT_CONTROL } from "@/constants/common";
import roles from "@/mixins/roles";
moment.locale("es");
export default {
  mixins: [validationMixin, roles],
  page: {
    title: "Proyectos",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
  },

  data() {
    return {
      loading: false,
      loadingInitial: false,
      countTotalInitialProjects: 0,
      filters: {
        filter_stage: [],
        filter: null,
        filter_sap: true,
        page: 1,
        items: 10,
        sort_by: "created_at",
        sort_order: "desc",
      },
      debounce: null,
      debounceTime: 500,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Listado",
          disabled: true,
        },
      ],
      proyectosUsuario: [],
      proyectosCargando: false,
      mensaje: { variant: "", texto: "" },
      proyectos: [],
      title: "Proyectos",
      items: [
        {
          text: "Proyectos",
          href: "/",
        },
        {
          text: "Lista",
          active: true,
        },
      ],
      totalRows: 0,
      perPage: 20,
      pageOptions: [10, 25, 50, 100],
      search: "",
      headers: [
        {
          value: "code",
          text: "Codigo Proyecto",
          sortable: true,
          align: "center",
          width: "10%",
        },
        {
          value: "name",
          text: "Nombre Proyecto",
          sortable: true,
          width: "30%",
        },
        {
          value: "stage",
          text: "Nombre Etapa Proyecto",
          sortable: true,
          align: "center",
          width: "10%",
        },
        {
          value: "created_at",
          sortable: true,
          align: "center",
          text: "Fecha Creación",
          width: "10%",
        },
      ],

      proyectoSeleccionado: {},
      etapasProyecto: [],
      etapasProyectoCargando: false,
      esEditar: false,
      submitted: false,
      mostrarModal: false,
      form: {
        codigoProyecto: null,
        nombreProyecto: null,
        montoProyecto: null,
        etapaProyecto: null,
        fechaCreacion: null,
        imagen: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        subEtapaProyecto: null,
        direccion: null,
        panel_especial: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      filtroEtapas: [],
      filtroSubEtapas: [],
      rolModuloPermiso: {},
      etapaProyecto: null,
      subEtapaProyecto: null,
      subEtapasProyecto: [],
      proyectosFiltrados: [],
      proyectosFiltradosEncontrados: [],
      tipoProyectos: [],
      tipoVentas: [],
      regiones: [],
      comunasRegion: [],
      zonas: [
        { value: "Norte", label: "Norte" },
        { value: "Centro", label: "Centro" },
        { value: "Sur", label: "Sur" },
        { value: "Insular", label: "Insular" },
      ],
      estados: [
        { value: "Activo", label: "Activo" },
        { value: "Cerrado", label: "Cerrado" },
        { value: "Detenido", label: "Detenido" },
        { value: "Nuevo", label: "Nuevo" },
        { value: "Terminado", label: "Terminado" },
      ],

      tipoProyectosCargando: false,
      tipoVentasCargando: false,
      regionesCargando: false,
      comunasRegionCargando: false,

      tipoInstalaciones: [],
      fasesInstalacion: [
        { value: "MONOFASICO", label: "MONOFASICO" },
        { value: "TRIFASICO", label: "TRIFASICO" },
        { value: "NO APLICA", label: "NO APLICA" },
      ],
      tipoClientes: [],
      clientesElectrico: [
        { value: "Cliente Regulado", label: "Cliente Regulado" },
        { value: "Cliente Libre", label: "Cliente Libre" },
        {
          value: "Desconectado/Aislado",
          label: "Desconectado/Aislado",
        },
      ],

      normativas: [],
      appFlexEnergeticas: [],
      tipoInstalacionesCargando: false,
      fasesInstalacionCargando: false,
      tipoClientesCargando: false,
      clientesElectricoCargando: false,
      normativasCargando: false,
      appFlexEnergeticasCargando: false,
      verTodosProyectos: false,
      subEtapasProyectoCargando: false,
      filtroEtapasSeleccionado: null,
      filtroSubEtapasSeleccionado: null,
    };
  },
  validations: {
    form: {
      codigoProyecto: { required },
      nombreProyecto: { required },
      montoProyecto: {},
      etapaProyecto: {},
      imagen: {},
      tipoProyecto: { required },
      tipoVenta: { required },
      estado: { required },
      subEtapaProyecto: {},
      direccion: { required },
      panel_especial: {},
      region: { required },
      comuna: { required },
      latitud: {},
      longitud: {},
      zona: {},
      potenciaPeak: {},
      potenciaAC: {},
      capacidadBaterias: {},
      tipoInstalacion: { required },
      faseInstalacion: { required },
      tipoCliente: { required },
      clienteElectrico: { required },
      normativa: { required },
      appFlexEnergetica: {},
      capacidadEmpalme: {},
    },
  },
  async mounted() {
    this.loadingInitial = true;
    let verTodosProyectosPermiso = permiso.obtenerPermisos(
      this.user,
      "verTodosProyectos"
    );
    this.verTodosProyectos = false;
    if (
      verTodosProyectosPermiso != null &&
      verTodosProyectosPermiso != undefined
    ) {
      this.verTodosProyectos = true;
    }
    await this.getFilteredProjectsData(1, true);
    // this.obtenerEtapaProyectoProyecto();
    this.getStagesProjects();
    this.getSpecialPanels();
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
    this.getEmployeeData();
  },
  destroyed() {
    this.cleanFilteredProjects();
  },
  watch: {
    "filters.page": function (page) {
      this.getFilteredProjectsData(page);
    },
  },
  methods: {
    ...proyectoMethods,
    ...etapaProyectoMethods,
    ...subEtapaProyectoMethods,
    ...tipoProyectoMethods,
    ...tipoVentaMethods,
    ...localizacionMethods,
    ...tipoClienteMethods,
    ...tipoInstalacionMethods,
    ...normativaMethods,
    ...appFlexEnergeticaMethods,
    ...mapActions({
      getFilteredProjects: "project/getFilteredProjects",
      cleanFilteredProjects: "project/cleanFilteredProjects",
      getSpecialPanels: "project/getSpecialPanels",
      syncProjects: "project/syncProjects",
      getStagesProjects: "project/getStagesProjects",
      getEmployees: "maintainers/getEmployees",
    }),
    async getEmployeeData() {
      let self = this;
      await self.getEmployees({
        password: config.password_manteiners,
        page: 0,
        filter: this.user.email,
      });
    },
    emitCreatePresale() {
      this.$emit("create-presale");
    },
    async updateSortBy(sortBy) {
      if (sortBy) {
        this.filters.sort_by = sortBy;
        this.filters.sort_order = "asc";
        await this.getFilteredProjectsData();
      } else {
        this.filters.sort_by = "created_at";
        this.filters.sort_order = "desc";
        await this.getFilteredProjectsData();
      }
    },
    async updateSortDesc(sortDesc) {
      if (sortDesc) {
        this.filters.sort_order = "desc";
        await this.getFilteredProjectsData();
      }
    },
    async getFilteredProjectsData(page = 1, firstTime = false) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = await setTimeout(async function () {
        self.filters.page = page;
        let projects = await self.getFilteredProjects({
          ...self.filters,
          user: self.user.email,
          all_projects: self.verTodosProyectos,
          filter_name: self.filters.filter,
          filter_sap: self.filters.filter_sap ? true : undefined,
          filter_stage: self.filters.filter_stage.length
            ? self.filters.filter_stage.toString()
            : undefined,
          sort_by: self.filters.sort_by,
          sort_order: self.filters.sort_order,
        });
        if (firstTime) {
          self.countTotalInitialProjects = projects.projectcount;
          self.loadingInitial = false;
        }
      }, self.debounceTime);
    },
    async sendSyncProjects() {
      this.$swal({
        icon: "info",
        title: "Atención",
        text: "La sincronización puede tardar unos minutos, una vez sincronizado el botón se habilitara nuevamente",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 6000,
        timerProgressBar: true,
      });
      const response = await this.syncProjects();
      if (response.status == 500) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intenta de nuevo mas tarde",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 200) {
        await this.getFilteredProjectsData();
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: `Listado de proyectos sincronizados con éxito`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    pageCount(item) {
      if (this.search != "") {
        this.totalRows = item;
      }
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
      this.obtenerEtapaProyectoProyecto();
      this.obtenerTipoProyectosProyecto();
      this.obtenerTipoVentasProyecto();
      this.obtenerRegionesProyecto();

      this.obtenerTipoInstalacionesProyecto();
      this.obtenerTipoClientesProyecto();
      this.obtenerNormativasProyecto();
      this.obtenerAppFlexEnergeticasProyecto();
    },

    abrirModalEditar(datos) {
      this.proyectoSeleccionado = datos;
      this.resetForm();
      this.obtenerEtapaProyectoProyecto();
      this.obtenerTipoProyectosProyecto();
      this.obtenerTipoVentasProyecto();
      this.obtenerRegionesProyecto();

      this.obtenerTipoInstalacionesProyecto();
      this.obtenerTipoClientesProyecto();
      this.obtenerNormativasProyecto();
      this.obtenerAppFlexEnergeticasProyecto();

      this.$v.form.codigoProyecto.$model = datos.codigo_proyecto;
      this.$v.form.nombreProyecto.$model = datos.nombre_proyecto;

      this.$v.form.nombreProyecto.$model = datos.nombre_proyecto;
      this.$v.form.montoProyecto.$model = datos.monto_proyecto;
      this.$v.form.latitud.$model = datos.latitud;
      this.$v.form.longitud.$model = datos.longitud;

      this.$v.form.direccion.$model = datos.direccion;
      this.$v.form.panel_especial.$model = datos.panel_especial;

      if (datos.zona != null) {
        this.$v.form.zona.$model = {
          value: datos.zona,
          label: datos.zona,
        };
      }
      this.$v.form.potenciaPeak.$model = datos.potencia_peak;
      this.$v.form.potenciaAC.$model = datos.potencia_ac;
      this.$v.form.capacidadBaterias.$model = datos.capacidad_baterias;
      this.$v.form.capacidadEmpalme.$model = datos.capacidad_empalme;

      if (datos.estado != null) {
        this.$v.form.estado.$model = {
          value: datos.estado,
          label: datos.estado,
        };
      }

      if (datos.fase_instalacion != null) {
        this.$v.form.faseInstalacion.$model = {
          value: datos.fase_instalacion,
          label: datos.fase_instalacion,
        };
      }

      if (datos.cliente_electrico != null) {
        this.$v.form.clienteElectrico.$model = {
          value: datos.cliente_electrico,
          label: datos.cliente_electrico,
        };
      }

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    obtenerNormativasProyecto() {
      this.normativasCargando = true;
      this.obtenerNormativas()
        .then((res) => {
          this.normativas = res.body;
          if (this.proyectoSeleccionado.normativa_id != null) {
            this.$v.form.normativa.$model = {
              normativa_id: this.proyectoSeleccionado.normativa_id,
              nombre_normativa: this.proyectoSeleccionado.nombre_normativa,
            };
          }
          this.normativasCargando = false;
        })
        .catch((error) => {
          this.normativasCargando = false;
        });
    },
    obtenerTipoInstalacionesProyecto() {
      this.tipoInstalacionesCargando = true;
      this.obtenerTipoInstalaciones()
        .then((res) => {
          this.tipoInstalaciones = res.body;
          if (this.proyectoSeleccionado.tipo_instalacion_id != null) {
            this.$v.form.tipoInstalacion.$model = {
              tipo_instalacion_id:
                this.proyectoSeleccionado.tipo_instalacion_id,
              nombre_tipo_instalacion:
                this.proyectoSeleccionado.nombre_tipo_instalacion,
            };
          }
          this.tipoInstalacionesCargando = false;
        })
        .catch((error) => {
          this.tipoInstalacionesCargando = false;
        });
    },
    obtenerTipoClientesProyecto() {
      this.tipoClientesCargando = true;
      this.obtenerTipoClientes()
        .then((res) => {
          this.tipoClientes = res.body;
          if (this.proyectoSeleccionado.tipo_cliente_id != null) {
            this.$v.form.tipoCliente.$model = {
              tipo_cliente_id: this.proyectoSeleccionado.tipo_cliente_id,
              nombre_tipo_cliente:
                this.proyectoSeleccionado.nombre_tipo_cliente,
            };
          }
          this.tipoClientesCargando = false;
        })
        .catch((error) => {
          this.tipoClientesCargando = false;
        });
    },
    obtenerAppFlexEnergeticasProyecto() {
      this.appFlexEnergeticasCargando = true;
      this.obtenerAppFlexEnergeticas()
        .then((res) => {
          this.appFlexEnergeticas = res.body;
          if (this.proyectoSeleccionado.app_flex_energetica_id != null) {
            this.$v.form.appFlexEnergetica.$model = {
              app_flex_energetica_id:
                this.proyectoSeleccionado.app_flex_energetica_id,
              nombre_app_flex_energetica:
                this.proyectoSeleccionado.nombre_app_flex_energetica,
            };
          }
          this.appFlexEnergeticasCargando = false;
        })
        .catch((error) => {
          this.appFlexEnergeticasCargando = false;
        });
    },
    obtenerRegionesProyecto() {
      this.regionesCargando = true;
      this.obtenerRegiones()
        .then((res) => {
          this.regiones = res.body;
          if (this.proyectoSeleccionado.codigo_comuna != null) {
            this.$v.form.comuna.$model = {
              codigo: this.proyectoSeleccionado.codigo_comuna,
              nombre: this.proyectoSeleccionado.nombre_comuna,
            };
          }
          if (this.proyectoSeleccionado.codigo_region != null) {
            this.$v.form.region.$model = {
              codigo: this.proyectoSeleccionado.codigo_region,
              nombre: this.proyectoSeleccionado.nombre_region,
            };
          }

          this.regionesCargando = false;
        })
        .catch((error) => {
          this.regionesCargando = false;
        });
    },
    obtenerComunasRegionProyecto() {
      this.comunasRegionCargando = true;
      this.$v.form.comuna.$model = null;
      let codigoRegion = this.$v.form.region.$model.codigo;
      this.obtenerComunasRegion(codigoRegion)
        .then((res) => {
          this.comunasRegion = res.body;
          this.comunasRegionCargando = false;
        })
        .catch((error) => {
          this.comunasRegionCargando = false;
        });
    },
    obtenerTipoProyectosProyecto() {
      this.tipoProyectosCargando = true;
      this.obtenerTipoProyectos()
        .then((res) => {
          this.tipoProyectos = res.body;
          if (this.proyectoSeleccionado.tipo_proyecto_id != null) {
            this.$v.form.tipoProyecto.$model = {
              tipo_proyecto_id: this.proyectoSeleccionado.tipo_proyecto_id,
              nombre_tipo_proyecto:
                this.proyectoSeleccionado.nombre_tipo_proyecto,
            };
          }
          this.tipoProyectosCargando = false;
        })
        .catch((error) => {
          this.tipoProyectosCargando = false;
        });
    },
    obtenerTipoVentasProyecto() {
      this.tipoVentasCargando = true;
      this.obtenerTipoVentas()
        .then((res) => {
          this.tipoVentas = res.body;
          if (this.proyectoSeleccionado.tipo_venta_id != null) {
            this.$v.form.tipoVenta.$model = {
              tipo_venta_id: this.proyectoSeleccionado.tipo_venta_id,
              nombre_tipo_venta: this.proyectoSeleccionado.nombre_tipo_venta,
            };
          }
          this.tipoVentasCargando = false;
        })
        .catch((error) => {
          this.tipoVentasCargando = false;
        });
    },
    obtenerEtapaProyectoProyecto() {
      this.etapasProyectoCargando = true;
      this.obtenerEtapasProyecto()
        .then((res) => {
          this.etapasProyecto = res.body;
          if (this.proyectoSeleccionado.etapa_proyecto_id != null) {
            this.$v.form.etapaProyecto.$model = {
              etapa_proyecto_id: this.proyectoSeleccionado.etapa_proyecto_id,
              nombre_etapa_proyecto:
                this.proyectoSeleccionado.nombre_etapa_proyecto,
            };
          }
          this.etapasProyectoCargando = false;
        })
        .catch((error) => {
          this.etapasProyectoCargando = false;
        });
    },
    obtenerSubEtapaProyectoProyecto() {
      this.etapasProyectoCargando = true;
      this.obtenerSubEtapasProyecto()
        .then((res) => {
          this.subEtapasProyecto = res.body;
          if (this.proyectoSeleccionado.sub_etapa_proyecto_id != null) {
            this.$v.form.subEtapaProyecto.$model = {
              sub_etapa_proyecto_id:
                this.proyectoSeleccionado.sub_etapa_proyecto_id,
              nombre_sub_etapa_proyecto:
                this.proyectoSeleccionado.nombre_sub_etapa_proyecto,
            };
          }
          this.subEtapasProyectoCargando = false;
        })
        .catch((error) => {
          this.subEtapasProyectoCargando = false;
        });
    },
    obtenerProyectosUsuario() {
      this.proyectosCargando = true;
      if (this.verTodosProyectos) {
        this.obtenerProyectos()
          .then((res) => {
            this.proyectos = res.body;
            this.proyectosFiltrados = res.body;
            this.totalRows = this.proyectosFiltrados.length;
            this.proyectosCargando = false;
          })
          .catch((error) => {
            this.proyectosCargando = false;
          });
      } else {
        this.obtenerProyectosContacto(this.user.email)
          .then((res) => {
            this.proyectos = res.body;
            this.proyectosFiltrados = res.body;
            this.totalRows = this.proyectosFiltrados.length;
            this.proyectosCargando = false;
          })
          .catch((error) => {
            this.proyectosCargando = false;
          });
      }
    },
    submit(e) {
      this.loading = true;
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.loading = false;
        return;
      }

      const codigo_proyecto = this.$v.form.codigoProyecto.$model;
      const nombre_proyecto = this.$v.form.nombreProyecto.$model;
      const etapa_proyecto_id =
        this.$v.form.etapaProyecto.$model.etapa_proyecto_id != null
          ? this.$v.form.etapaProyecto.$model.etapa_proyecto_id
          : null;

      const nombre_etapa_proyecto =
        this.$v.form.etapaProyecto.$model != null
          ? this.$v.form.etapaProyecto.$model.nombre_etapa_proyecto
          : null;
      const sub_etapa_proyecto_id = null;

      const nombre_sub_etapa_proyecto =
        this.$v.form.subEtapaProyecto.$model != null
          ? this.$v.form.subEtapaProyecto.$model.nombre_sub_etapa_proyecto
          : null;

      const imagen = "lugarInstalacion.jpg";
      const email_usuario = this.user.email;

      const monto_proyecto =
        this.$v.form.montoProyecto.$model != null
          ? this.$v.form.montoProyecto.$model
          : null;

      const tipo_proyecto_id =
        this.$v.form.tipoProyecto.$model.tipo_proyecto_id != null
          ? this.$v.form.tipoProyecto.$model.tipo_proyecto_id
          : null;

      const nombre_tipo_proyecto =
        this.$v.form.tipoProyecto.$model.nombre_tipo_proyecto != null
          ? this.$v.form.tipoProyecto.$model.nombre_tipo_proyecto
          : null;

      const tipo_venta_id =
        this.$v.form.tipoVenta.$model.tipo_venta_id != null
          ? this.$v.form.tipoVenta.$model.tipo_venta_id
          : null;
      const nombre_tipo_venta =
        this.$v.form.tipoVenta.$model.nombre_tipo_venta != null
          ? this.$v.form.tipoVenta.$model.nombre_tipo_venta
          : null;

      const direccion =
        this.$v.form.direccion.$model != null
          ? this.$v.form.direccion.$model
          : null;

      const panel_especial =
        this.$v.form.panel_especial.$model != null
          ? this.$v.form.panel_especial.$model
          : null;

      const codigo_comuna =
        this.$v.form.comuna.$model.codigo != null
          ? this.$v.form.comuna.$model.codigo
          : null;

      const nombre_comuna =
        this.$v.form.comuna.$model.nombre != null
          ? this.$v.form.comuna.$model.nombre
          : null;

      const codigo_region =
        this.$v.form.region.$model.codigo != null
          ? this.$v.form.region.$model.codigo
          : null;
      const nombre_region =
        this.$v.form.region.$model.nombre != null
          ? this.$v.form.region.$model.nombre
          : null;
      let latitud =
        this.$v.form.latitud.$model != null
          ? this.$v.form.latitud.$model
          : null;
      let longitud =
        this.$v.form.longitud.$model != null
          ? this.$v.form.longitud.$model
          : null;

      const zona =
        this.$v.form.zona.$model != null
          ? this.$v.form.zona.$model.value
          : null;

      const estado =
        this.$v.form.estado.$model != null
          ? this.$v.form.estado.$model.value
          : null;

      latitud = latitud != null ? latitud.toString().replace(",", ".") : null;
      longitud =
        longitud != null ? longitud.toString().replace(",", ".") : null;

      //Informacion tecnica

      const potencia_peak =
        this.$v.form.potenciaPeak.$model != null
          ? this.$v.form.potenciaPeak.$model
          : null;
      const potencia_ac =
        this.$v.form.potenciaAC.$model != null
          ? this.$v.form.potenciaAC.$model
          : null;
      const capacidad_baterias =
        this.$v.form.capacidadBaterias.$model != null
          ? this.$v.form.capacidadBaterias.$model
          : null;
      const capacidad_empalme =
        this.$v.form.capacidadEmpalme.$model != null
          ? this.$v.form.capacidadEmpalme.$model
          : null;
      const tipo_instalacion_id =
        this.$v.form.tipoInstalacion.$model.tipo_instalacion_id != null
          ? this.$v.form.tipoInstalacion.$model.tipo_instalacion_id
          : null;
      const nombre_tipo_instalacion =
        this.$v.form.tipoInstalacion.$model.nombre_tipo_instalacion != null
          ? this.$v.form.tipoInstalacion.$model.nombre_tipo_instalacion
          : null;
      const fase_instalacion =
        this.$v.form.faseInstalacion.$model != null
          ? this.$v.form.faseInstalacion.$model.value
          : null;
      const tipo_cliente_id =
        this.$v.form.tipoCliente.$model.tipo_cliente_id != null
          ? this.$v.form.tipoCliente.$model.tipo_cliente_id
          : null;
      const nombre_tipo_cliente =
        this.$v.form.tipoCliente.$model.nombre_tipo_cliente != null
          ? this.$v.form.tipoCliente.$model.nombre_tipo_cliente
          : null;
      const normativa_id =
        this.$v.form.normativa.$model.normativa_id != null
          ? this.$v.form.normativa.$model.normativa_id
          : null;
      const nombre_normativa =
        this.$v.form.normativa.$model.nombre_normativa != null
          ? this.$v.form.normativa.$model.nombre_normativa
          : null;
      const cliente_electrico =
        this.$v.form.clienteElectrico.$model != null
          ? this.$v.form.clienteElectrico.$model.value
          : null;

      const app_flex_energetica_id = null;
      const nombre_app_flex_energetica = null;

      if (this.esEditar) {
        this.actualizarProyecto({
          codigo_proyecto,
          nombre_proyecto,
          etapa_proyecto_id,
          nombre_etapa_proyecto,
          sub_etapa_proyecto_id,
          nombre_sub_etapa_proyecto,
          imagen,
          monto_proyecto,
          tipo_proyecto_id,
          nombre_tipo_proyecto,
          estado,
          panel_especial,
          tipo_venta_id,
          nombre_tipo_venta,
          direccion,
          codigo_comuna,
          nombre_comuna,
          codigo_region,
          nombre_region,
          latitud,
          longitud,
          zona,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 202) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            }

            if (res.status == 204) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Proyecto actualizado correctamente!!!";
              this.mostrarModal = false;
            }
            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerProyectosUsuario();
            this.loading = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModal = false;
            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });
      } else {
        this.crearProyecto({
          codigo_proyecto,
          nombre_proyecto,
          etapa_proyecto_id,
          nombre_etapa_proyecto,
          sub_etapa_proyecto_id,
          nombre_sub_etapa_proyecto,
          imagen,
          email_usuario,
          monto_proyecto,
          tipo_proyecto_id,
          nombre_tipo_proyecto,
          estado,
          panel_especial,
          tipo_venta_id,
          nombre_tipo_venta,
          direccion,
          codigo_comuna,
          nombre_comuna,
          codigo_region,
          nombre_region,
          latitud,
          longitud,
          zona,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 201) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Proyecto creado correctamente!!!";
              this.mostrarModal = false;

              this.irLugarinstalacion({
                codigo_proyecto: codigo_proyecto,
              });
            }

            if (res.status == 208) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "El proyecto ya existe!!!";
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.getFilteredProjectsData();
            this.loading = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModal = false;
            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });
      }

      this.submitted = false;
    },
    eliminarProyectoLocal(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-16",
          confirmButton: "btn btn-primary me-2",
          cancelButton: "btn btn-light",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarProyecto(item.proyecto_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto = "Proyecto eliminado correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.getFilteredProjectsData();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    resetForm() {
      this.form = {
        codigoProyecto: null,
        nombreProyecto: null,
        etapaProyecto: null,
        subEtapaProyecto: null,
        fechaCreacion: null,
        imagen: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        direccion: null,
        panel_especial: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    irLugarinstalacion(proyecto) {
      this.seleccionarProyecto(proyecto);
      this.$router.push({
        name: "lugaresInstalacion",
        params: { codigoProyecto: proyecto.code },
        query: proyecto.is_sap ? { sap_project: proyecto.code } : undefined,
      });
    },
    obtenerColorEtapa(nombre_etapa_proyecto) {
      return "#3f51b5";
    },
    filtro() {
      let filtroProyecto = {
        etapas: this.filtroEtapas,
        sub_etapas: this.filtroSubEtapas,
      };
      this.buscarFiltroEtapas(filtroProyecto)
        .then((res) => {
          let filtro = [];
          let filtro2 = [];
          this.proyectos = res.body;
          this.totalRows = this.proyectosFiltrados.length;
          this.proyectosCargando = false;

          if (
            this.filtroEtapas.length == 0 &&
            this.filtroSubEtapas.length == 0
          ) {
            this.obtenerProyectosUsuario();
            this.proyectosCargando = false;
          } else {
            if (this.filtroEtapas.length > 0) {
              this.filtroEtapas.forEach((etapa_proyecto_id) => {
                filtro.push(
                  ...this.proyectos.filter(
                    (val) => val.etapa_proyecto_id == etapa_proyecto_id
                  )
                );
              });
              filtro2 = filtro;
            } else {
              filtro2 = this.proyectos;
            }

            if (
              this.filtroEtapas.length > 0 &&
              this.filtroSubEtapas.length > 0
            ) {
              filtro = [];
              this.filtroSubEtapas.forEach((sub_etapa_proyecto_id) => {
                filtro.push(
                  ...filtro2.filter(
                    (val) => val.sub_etapa_proyecto_id == sub_etapa_proyecto_id
                  )
                );
              });
            } else {
              this.filtroSubEtapas.forEach((sub_etapa_proyecto_id) => {
                filtro.push(
                  ...this.proyectos.filter(
                    (val) => val.sub_etapa_proyecto_id == sub_etapa_proyecto_id
                  )
                );
              });
              filtro = filtro2;
            }

            this.proyectosFiltrados = filtro;
            this.totalRows = this.proyectosFiltrados.length;
          }
        })
        .catch((error) => {
          console.log(error);
          this.proyectosCargando = false;
        });
    },
    filtrarPorEtapas(item) {
      this.proyectosCargando = true;
      this.filtroEtapas.push(item.etapa_proyecto_id);
      this.filtroEtapasSeleccionado = this.filtroEtapas;

      const filtro = [];
      this.filtroEtapas.forEach((etapa_proyecto_id) => {
        filtro.push(
          ...this.proyectos.filter(
            (val) => val.etapa_proyecto_id == etapa_proyecto_id
          )
        );
      });
      this.proyectosFiltrados = filtro;
      this.proyectosCargando = false;
      this.totalRows = this.proyectosFiltrados.length;
    },
    removerFiltroPorEtapas(etapa, id) {
      const filtro = [];
      let filtroNuevo = this.filtroEtapas.filter(
        (item) => item != etapa.etapa_proyecto_id
      );
      this.filtroEtapas = filtroNuevo;
      if (filtroNuevo.length == 0) {
        this.proyectosFiltrados = this.proyectos;
        this.totalRows = this.proyectosFiltrados.length;
      } else {
        filtroNuevo.forEach((etapa_proyecto_id) => {
          filtro.push(
            ...this.proyectos.filter(
              (val) => val.etapa_proyecto_id == etapa_proyecto_id
            )
          );
        });
        this.proyectosFiltrados = filtro;
        this.totalRows = this.proyectosFiltrados.length;
      }
    },
    filtrarPorSubEtapas(item) {
      this.filtroSubEtapas.push(item.sub_etapa_proyecto_id);
      this.filtro();
    },
    removerFiltroPorSubEtapas(etapa, id) {
      let filtroNuevo = this.filtroSubEtapas.filter(
        (item) => item != etapa.sub_etapa_proyecto_id
      );
      this.filtroSubEtapas = filtroNuevo;
      this.filtro();
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      stages: "project/stages",
      filteredProjects: "project/filteredProjects",
      stagesProjects: "project/stagesProjects",
      loadingProjects: "project/loading",
      specialPanels: "project/specialPanels",
      loadingSyncProjects: "project/loadingSyncProjects",
      dataEmployees: "maintainers/employees",
    }),
    verifyUserBelongsToCgestionCostCenter() {
      return (
        this.dataEmployees.employees.length &&
        this.dataEmployees.employees.find(
          (employee) =>
            employee.cost_center.code == COST_CENTER_MANAGMENT_CONTROL
        )
      );
    },
  },
};
</script>

<style scoped>
.proyectos-all-list >>> .v-data-table__mobile-table-row {
  border-bottom: 3px solid #b2b2b2;
}
</style>

<style>
.irLugarinstalacion:hover {
  cursor: pointer;
  color: #556ee6;
}

#tabla-proyectos .v-data-footer {
  justify-content: end;
}
#tabla-proyectos .v-data-footer__select {
  display: none;
}
</style>
