export const ACTIVE_BUSINESS_UNITS = [
    'RESIDENCIAL',
    'COMERCIAL INDUSTRIAL',
    'PROYECTOS DE GENERACION'
]

export const TYPE_PROJECTS = [
    {
        id: 'B2C',
        name: 'B2C',
    },
    {
        id: 'B2B',
        name: 'B2B',
    }
]