<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalLinkPresaleToProject"
      @click:outside="executeCloseModalLinkPresaleToProject"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5 v-if="currentPresale">
            Enlazar Preventa de Código: <b>{{ currentPresale.code }}</b> a un
            Proyecto
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-form @submit.prevent="sendLinkPresaleToProject">
              <v-row align-content="center" class="mt-2">
                <v-col cols="12" md="12" sm="12" class="pb-0 mt-1">
                  <label for="" class=""> Proyecto a Enlazar </label>
                  <v-autocomplete
                    v-model="form.project_code"
                    clearable
                    class="pt-0"
                    :items="projects.items ? projects.items : []"
                    hide-details
                    @update:search-input="getProjectsData"
                    @click:clear="getProjectsData"
                    no-data-text="Sin proyectos"
                    :loading="loadingProjects"
                    item-text="name_code"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalLinkPresaleToProject"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            @click="sendLinkPresaleToProject"
            color="primary text-white"
            small
            class="float-right"
          >
            Enlazar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalLinkPresaleToProject: {
      type: Function,
      default: () => {},
    },
    currentPresale: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      loadingProjects: false,
      debounceProjects: null,
      debounceProjectsTime: 500,
      form: {
        project_code: null,
      },
      submitUpload: false,
    };
  },
  validations: {
    form: {
      project_code: { required },
    },
  },
  mounted() {
    this.getProjectsData();
  },
  methods: {
    ...mapActions({
      linkPresaleToProject: "presale/linkPresaleToProject",
      getProjects: "requestMaterialsSap/getProjects",
    }),
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.form.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function () {
          await self.getProjects({
            is_residential: true,
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceProjectsTime);
      }
    },
    async sendLinkPresaleToProject() {
      let self = this;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        self.loading = true;
        const resp = await self.linkPresaleToProject({
          project_code: self.form.project_code.split(" - ")[0],
          pre_project_code: self.currentPresale.code,
        });
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: `Proyecto de Preventa: ${self.currentPresale.code} enlazado correctamente con el proyecto: ${self.form.project_code}`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 7000,
            timerProgressBar: true,
          });
          this.$emit("presaleLinkedToProject");
          this.closeModalLinkPresaleToProject(true);
          this.resetForm();
        }
        if (resp.status === 409 || resp.status === 404) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalLinkPresaleToProject($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalLinkPresaleToProject($event);
      }
    },
    resetForm() {
      this.form = {
        project_code: null,
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
    }),
  },
};
</script>

<style></style>
