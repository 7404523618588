export const MAP_MONTHS = {
    '01': 'Enero',
    '02': 'Febrero', 
    '03': 'Marzo',
    '04': 'Abril',
    '05': 'Mayo',
    '06': 'Junio',
    '07': 'Julio',
    '08': 'Agosto',
    '09': 'Septiembre',
    '10': 'Octubre',
    '11': 'Noviembre',
    '12': 'Diciembre'  
}

export const OPTIONS_AMOUNT_FORMATTING = {
    style: "decimal",
    useGrouping: true,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
}

export const COST_CENTER_MANAGMENT_CONTROL = "AF-CDG"