<template>
  <section class="p-4">
    <v-tabs fixed-tabs centered v-model="tab" active-class="bg-primary">
      <v-tab
        key="projects"
        active-class="bg-app-primary text-white rounded"
        class="tab-list"
        >Proyectos</v-tab
      >
      <v-tab
        id="tab-presale"
        class="tab-list"
        active-class="bg-app-primary text-white rounded"
        key="presale"
        >Preventas</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="projects">
        <index-projects @create-presale="createPresale"></index-projects>
      </v-tab-item>
      <v-tab-item key="presale">
        <index-presale ref="listPresale"></index-presale>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import indexProjects from "./indexProjects.vue";
import indexPresale from "../presale/index.vue";
import { mapActions } from "vuex";
export default {
  components: { indexProjects, indexPresale },
  data() {
    return {
      tab: "presale",
    };
  },
  mounted() {
    this.getBusinessUnits();
    if (this.$route.query.presale_tab) {
      this.tab = "presale";
      document.getElementById("tab-presale").click();
    }
  },
  methods: {
    ...mapActions({
      getBusinessUnits: "common/getBusinessUnits",
    }),
    createPresale() {
      document.getElementById("tab-presale").click();
      setTimeout(() => {
        this.$refs.listPresale.openModalUpdateOrCreatePresale();
      }, 200);
    },
  },
};
</script>

<style></style>
